import React from "react";

import "./blogBox.scss";





const blog = (props) => (
    <div className="blog__box">
      <div className="blog__image">
        <img src={props.article.image} alt="blog story" />
        
      </div>
      <div className="blog__info">
        <h4 className="font15 weight800">{props.article.title}</h4>
        
        <h4 className="font12 weight500 padding10">{props.article.description}</h4>
        
        <center><a href={props.article.link}>
      <div className="small__button">
        <button aria-label="send message" type="submit" value="Send Message">
        {props.article.title}
        </button>
      </div>
      </a></center>
      </div>
    </div>
  );

export default blog;


